<template>
  <div class="content-page">
    <div class="content-nav">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item>商品列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="operation-nav">
     
        <el-button type="primary" icon="plus" size="small" @click="addGoodsClick()"
          >添加商品</el-button
        >
      </div>
    </div>
    <div class="content-cate">
      <el-tree
        :data="categoryData"
        :props="defaultProps"
        node-key="id"
        default-expand-all
        show-checkbox
        @check-change="handleNodeClick"
      ></el-tree>
    </div>

    <div class="content-main">
      <div class="block">
        <span class="wrapper">
          <el-button
            :plain="true"
            type="primary"
            :class="activeClass == 1 ? 'active' : ''"
            @click="sortOrder(1)"
            size="small"
            >按销量排序</el-button
          >
          <el-button
            :plain="true"
            type="primary"
            :class="activeClass == 2 ? 'active' : ''"
            @click="sortOrder(2)"
            size="small"
            >按售价排序</el-button
          >
          <el-button
            :plain="true"
            type="primary"
            :class="activeClass == 3 ? 'active' : ''"
            @click="sortOrder(3)"
            size="small"
            >按库存排序</el-button
          >
          <el-button
            :plain="true"
            type="primary"
            :class="activeClass == 4 ? 'active' : ''"
            @click="sortOrder(4)"
            size="small"
            >按添加时间排序</el-button
          >
          <el-button
            :plain="true"
            type="primary"
            :class="activeClass == 5 ? 'active' : ''"
            @click="sortOrder(5)"
            size="small"
            >按更新时间排序</el-button
          >
        
        </span>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部商品" name="first"> </el-tab-pane>
        <el-tab-pane label="出售中" name="second"></el-tab-pane>
        <el-tab-pane label="已售完" name="third"></el-tab-pane>
        <el-tab-pane label="已下架" name="fourth"></el-tab-pane>
      </el-tabs>
      <div class="filter-box">
        <el-form :inline="true" :model="filterForm" class="form-inline">
          <el-form-item label="商品名称">
            <el-input size="small" v-model="filterForm.name" placeholder="商品名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="onSubmitFilter">查询</el-button>
            <el-button size="small" @click="clear">清空</el-button>
          </el-form-item>
        </el-form>
        <!--<el-button @click="expandToggle" type="primary" icon="plus" size="small">{{expand == false?'全部展开':'全部收起'}}</el-button>-->
      </div>

      <div class="form-table-box">
        <el-table :data="tableData" style="width: 100%" stripe>
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table :data="props.row.product" style="width: 100%" stripe>
                <el-table-column prop="id" label="id" width="60"></el-table-column>
                <el-table-column prop="goods_sn" label="SKU" width="100">
                  <template scope="scope">
                    <el-input
                      :disabled='true'
                      @blur="checkSkuOnly(scope.$index, scope.row)"
                      size="mini"
                      v-model="scope.row.goods_sn"
                      placeholder="商品SKU"
                    ></el-input>
                  </template>
                </el-table-column>
            
                <el-table-column prop="value" label="型号/规格" width="250">
                  <template scope="scope">
                    <el-input
                    :disabled='true'
                      size="mini"
                      v-model="scope.row.value"
                      placeholder="如1斤/条"
                    ></el-input>
                  </template>
                </el-table-column>
               
                <el-table-column prop="retail_price" label="零售(元)" width="90">
                  <template scope="scope">
                    <el-input
                    :disabled='true'
                      size="mini"
                      v-model="scope.row.retail_price"
                      placeholder="零售"
                    ></el-input>
                  </template>
                </el-table-column>
            

                <el-table-column prop="goods_weight" label="重量(KG)" width="90">
                  <template scope="scope">
                    <el-input
                    :disabled='true'
                      size="mini"
                      v-model="scope.row.goods_weight"
                      placeholder="重量"
                    ></el-input>
                  </template>
                </el-table-column>

                <el-table-column prop="min_buy" label="起订量" width="90">
                  <template scope="scope">
                    <el-input size="mini" 
                    :disabled='true'
                    v-model="scope.row.min_buy" placeholder="重量"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="goods_number" label="库存" width="90">
                  <template scope="scope">
                    <el-input
                    :disabled='true'
                      size="mini"
                      v-model="scope.row.goods_number"
                      placeholder="库存"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="sort" label="排序" width="140" sortable>
                  <template scope="scope">
                    <el-input-number
                    
                      class="sort-width"
                      size="mini"
                      controls-position="right"
                      v-model="scope.row.sort"
                      placeholder="排序"
                      @blur="submitProductSort(scope.$index, scope.row)"
                      @change="submitProductSort(scope.$index, scope.row)"
                    ></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column prop="is_on_sale" label="状态" width="80" sortable>
                  <template scope="scope">
                    <el-switch
                      size="mini"
                      v-model="scope.row.is_on_sale"
                      active-text=""
                      inactive-text=""
                      active-value="1"
                      inactive-value="0"
                      @change="changeProductStatus($event, scope.row.id)"
                    >
                    </el-switch>
                  </template>
                </el-table-column>

                <el-table-column prop="is_hot" label="热门" width="80" sortable>
                  <template scope="scope">
                    <el-switch
                      size="mini"
                      v-model="scope.row.is_hot"
                      active-text=""
                      inactive-text=""
                      :active-value="1"
                      :inactive-value="0"
                      @change="addHotRecord($event, scope.row.spec_id)"
                    >
                    </el-switch>
                  </template>
                </el-table-column>

              </el-table>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="ID" width="120"></el-table-column>
          <el-table-column prop="list_pic_url" label="商品图片" width="80">
            <template scope="scope">
              <img :src="scope.row.list_pic_url" alt="" style="width: 40px; height: 40px" />
            </template>
          </el-table-column>
          <el-table-column prop="name" label="商品名称"></el-table-column>
          <el-table-column prop="sort_order" label="排序" width="140" sortable>
            <template scope="scope">
              <el-input-number
                class="sort-width"
                size="mini"
                controls-position="right"
                v-model="scope.row.sort_order"
                placeholder="排序"
                @blur="submitSort(scope.$index, scope.row)"
                @change="submitSort(scope.$index, scope.row)"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="sell_volume" label="销量" width="80" sortable></el-table-column>
          <el-table-column prop="goods_number" label="库存" width="80" sortable> </el-table-column>
         
          <el-table-column label="上架" width="80">
            <template scope="scope">
              <el-switch
                v-model="scope.row.is_on_sale"
                active-text=""
                inactive-text=""
                @change="changeStatus($event, scope.row.id)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="250">
            <template scope="scope">
              <el-button size="small" @click="handleRowEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                size="small"
                plain
                type="danger"
                @click="handleRowDelete(scope.$index, scope.row)"
                >删除
              </el-button>
              <el-button round type="success" size="medium" @click="openTips"
                >上首页</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page-box">
        <el-pagination
          @current-change="handlePageChange"
          :current-page="page"
          :page-size="size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

  
  </div>
</template>

<script>
export default {
  data() {
    return {
      gbbSkuId: '',
      gbbCateId: '',
      allCateArray: [],
      dialogFormVisible: false,
      page: 1,
      size: 10,
      total: 0,
      selectCate: '',
      filterForm: {
        name: localStorage.getItem('goodsqueryname')
      },
      tableData: [],
      categoryData: [],
      defaultProps: {
        children: 'children',
        label: function (data, node) {
          // console.log(data, node);
          return data.name;
        }
      },
      activeName: 'second',
      pIndex: 1,
      num: 0,
      activeClass: 0,
      expand: true,

      tableDa: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ]
    };
  },
  methods: {
   openTips() {
      this.$alert("请联系您的商务经理帮助您开通上首页", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.axios
            .get("dynamic/addClick", {
              params: {
                event: "goods",
              },
            })
            .then((response) => {});
        },
      });
    },
   
    updateGoodsNumber() {
      this.axios.post('goods/updateGoodsNumber').then((response) => {
        if (response.data.errno === 0) {
          this.$message({
            type: 'success',
            message: '同步成功2/2，完成'
          });
        }
      });
    },
    specSave(index, row) {
      if (
        row.goods_name == '' ||
        row.value == '' ||
        row.cost == '' ||
        row.retail_price == '' ||
        row.goods_weight == ''
      ) {
        this.$message({
          type: 'error',
          message: '值不能为空!'
        });
        return false;
      }
      this.axios.post('goods/updatePrice', row).then((response) => {
        if (response.data.errno === 0) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
        }
      });
    },
    updateWithdraw() {
      this.axios.post('goods/getAllWithdrawField').then((response) => {
        if (response.data.errno === 0) {
          this.$message({
            type: 'success',
            message: '更新' + response.data.data + '条商品数据成功'
          });
        } else {
          this.$message({
            type: 'fail',
            message: '更新失败,联系管理员处理'
          });
        }
      });
    },
    checkSkuOnly(index, row) {
      console.log(index);
      console.log(row);
      if (row.goods_sn == '') {
        this.$message({
          type: 'error',
          message: 'SKU不能为空'
        });
        return false;
      }
      this.axios.post('goods/checkSku', { info: row }).then((response) => {
        if (response.data.errno === 100) {
          this.$message({
            type: 'error',
            message: '该SKU已存在！'
          });
        } else {
          this.$message({
            type: 'success',
            message: '该SKU可以用！'
          });
        }
      });
    },
    checkWithdrawWithRetail(index, row) {
      if (row.retail_price > 0 && row.cost > 0) {
        var withdraw = ((row.retail_price - row.cost) * 0.1).toFixed(2);
        if (withdraw >= 10) {
          withdraw = Math.floor(withdraw);
        }
        row.withdraw = withdraw;
      }
    },
    checkWithdraw(index, row) {
      if (row.cost > 0) {
        //暂时先搞粗暴点
        var price = (row.cost * 1.15).toFixed(2);
        // price = Math.ceil(price);
        row.retail_price = price;

        var withdraw = ((row.retail_price - row.cost) * 0.1).toFixed(2);
        if (withdraw >= 10) {
          withdraw = Math.floor(withdraw);
        }
        row.withdraw = withdraw;
      }
    },
    expandToggle() {
      this.expand = !this.expand;
    },
    test() {
      console.log(this.tableData);
    },
    submitName(index, row) {
      this.axios
        .post('goods/updateShortName', {
          id: row.id,
          short_name: row.short_name
        })
        .then((response) => {
          if (response.data.errno === 0) {
            this.$message({
              type: 'success',
              message: '修改成功!'
            });
          }
        });
    },
    submitSort(index, row) {
      this.axios
        .post('goods/updateSort', { id: row.id, sort: row.sort_order })
        .then((response) => {});
    },
    submitProductSort(index, row) {
      this.axios
        .post('goods/updateProductSort', { id: row.id, sort: row.sort })
        .then((response) => {});
    },
    handleClick(tab, event) {
      this.pIndex = tab._data.index;
      this.page = 1;
      localStorage.setItem('goodspage', this.page);
      this.activeClass = 0;
      this.getOnSaleList();
    },
    handlePageChange(val) {
      this.page = val;
      localStorage.setItem('goodspage', this.page);
      this.getOnSaleList();
    },
    handleRowEdit(index, row) {
      this.$router.push({
        name: 'goods_add',
        query: { id: row.id, page: this.page }
      });
    },
    addGoodsClick() {
      this.$router.push({ name: 'goods_add', query: { page: this.page } });
    },
    goFileUpload() {
      this.$router.push({ name: 'fileupload' });
    },
    handleRowDelete(index, row) {
      this.$confirm('确定要删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let that = this;
          that.axios.post('goods/destory', { id: row.id }).then((response) => {
            if (response.data.errno === 0) {
              that.$message({
                type: 'success',
                message: '删除成功!'
              });
              that.getOnSaleList();
            }
          });
        })
        .catch(() => {
          //                    this.$message({
          //                        type: 'info',
          //                        message: '已取消删除'
          //                    });
        });
    },
    onSubmitFilter() {
      this.page = 1;
      localStorage.setItem('goodspage', this.page);
      localStorage.setItem('goodsqueryname', this.filterForm.name);

      this.getOnSaleList();
    },
    clear() {
      this.filterForm.name = '';
      localStorage.setItem('goodsqueryname', '');

      this.getOnSaleList();
    },

    getOnSaleList() {
      this.axios
        .get('goods/onsale', {
          params: {
            page: this.page,
            size: this.size,
            cate: this.selectCate,
            type: this.pIndex,
            order: this.num,
            name: this.filterForm.name
          }
        })
        .then((response) => {
          this.tableData = response.data.data.data;
          this.page = response.data.data.currentPage;
          this.total = response.data.data.count;
        });
    },

    sortOrder(num) {
      this.num = num;
      this.getOnSaleList();
    },
    changeStatus($event, para) {
      this.axios
        .get('goods/saleStatus', {
          params: {
            status: $event,
            id: para
          }
        })
        .then((response) => {});
    },
    changeProductStatus($event, para) {
      this.axios
        .get('goods/productStatus', {
          params: {
            status: $event,
            id: para
          }
        })
        .then((response) => {});
    },
    addPriceRecord($event, para) {
      this.axios
        .get('goods/recordStatus', {
          params: {
            status: $event,
            id: para
          }
        })
        .then((response) => {});
    },
    addHotRecord($event, para) {
      this.axios
        .get('goods/hotStatus', {
          params: {
            status: $event,
            id: para
          }
        })
        .then((response) => {});
    },
    changeShowStatus($event, para) {
      this.axios
        .get('goods/indexShowStatus', {
          params: {
            status: $event,
            id: para
          }
        })
        .then((response) => {});
    },
    getCateList() {
      this.axios
        .get('category/allCate', {
        
        })
        .then((response) => {
          this.categoryData = response.data.data;
          for (const item of this.categoryData) {
              this.allCateArray.push(item);
          }
        });
    },
    handleNodeClick(data, checked, tree) {
      if (checked) {
        this.selectCate = data.id;
        this.$nextTick(() => {
          // this.$refs.tree.setCheckedNodes([data]);
        });
        this.getOnSaleList();
      } else {
        this.selectCate = '';
      }
    }
  },
  components: {},
  mounted() {
    let page = localStorage.getItem('goodspage') || '1';
    this.page = page;
    this.getCateList();
    this.getOnSaleList();
  }
};
</script>

<style scoped>
.sort-width {
  width: 90px;
}
.right {
  float: right;
}
.form-inline {
  margin-top: 2px;
  height: 40px;
  margin-right: 20px;
}

.block {
  margin-bottom: 10px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.active {
  border-color: #ff4949;
  color: #ff4949;
}

.marginRight {
  margin-right: 20px;
}

.content-main {
  margin-left: 220px;
  margin-top: -600px;
}
.content-cate {
  width: 200px;
  overflow: auto;
  height: 600px;
}
</style>
